import React from "react";
import "./Home.css";
import background from "../images/background.png";

function Home() {
  return (
    <section className="home">
      <img src={background} alt="Background" className="background-image" />
      <div className="overlay"></div>
      <div className="content">
        <div className="first-line">Happy Birthday, Kit! 🎉 </div>
        <div>
          Can’t believe another year has gone by—feels like we’ve grown so much,
          both in life and this company (and all the chaos that comes with it
          😅). Honestly, I couldn’t ask for a better friend and partner in
          crime. You always know how to keep things fun and real, no matter how
          stressful things get. Here’s to many more late nights, big wins, and
          maybe a bit of sleep in between! Wishing you nothing but happiness,
          success, and all the good vibes today and every day. Let’s keep
          crushing it together!
        </div>
        <div className="last-line">Love ya, Cress 💖 </div>
      </div>
    </section>
  );
}

export default Home;
